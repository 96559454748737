import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, TextField, Button, Typography, AppBar, Toolbar, Container,
  List, ListItem, ListItemText, IconButton, Dialog, DialogTitle,
  DialogContent, DialogActions, CircularProgress, FormGroup, FormControlLabel,
  Checkbox, Divider, Alert
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';

const possibleDesignations = ["GE-", "GF-", "GS-", "GN-", "GD-"];
const possibleTopics = ["Measuring", "Installation", "Delivery", "Inspection", "Reclamation", "Extra"];

const AdminPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(localStorage.getItem('adminToken'));
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [editUser, setEditUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const apiUrls = [
    process.env.REACT_APP_API_URL_PROD
  ];

const fetchUsers = async (token) => {
  for (const url of apiUrls) {
    try {
      const response = await axios.get(`${url}/admin/users`, {
        headers: { Authorization: token }
      });
      setUsers(response.data);
      return; // Exit once successful
    } catch (error) {
      console.error(`Error fetching users from ${url}:`, error);
      if (error.response && 
         (error.response.status === 401 || 
          (error.response.status === 403 && error.response.data === 'TokenExpired'))) {
        handleLogout(); // Trigger logout if token is expired or invalid
      } else {
        setError('Error fetching users');
      }
    }
  }
};

useEffect(() => {
  if (token) {
    fetchUsers(token);
  } else {
    handleLogout(); // Call handleLogout to clear the token and redirect
  }
}, [token]);


  const handleLogin = async () => {
    setLoading(true);
    setError('');

    for (const url of apiUrls) {
      try {
        const response = await axios.post(`${url}/admin/login`, { email, password });
        if (response.status === 200) {
          localStorage.setItem('adminToken', response.data.token);
          setToken(response.data.token);
          fetchUsers(response.data.token);
          setLoading(false);
          return; // Exit once successful
        }
      } catch (error) {
        setError('Login failed');
      }
    }

    setLoading(false);
  };

  const handleAddUser = () => {
    setEditUser({ email: '', id: '', designations: [], topics: [] });
    setShowAlert(false);
    setAlertMessage('');
  };

const handleSaveUser = async () => {
  if (editUser) {
    const { email, id } = editUser;

    // Check for uniqueness
    const isEmailUnique = !users.some((user) => user.email === email && user.email !== editUser.email);
    const isIdUnique = !users.some((user) => user.id === id && user.email !== editUser.email);

    if (!isEmailUnique) {
      setAlertMessage('Email must be unique');
      setShowAlert(true);
      return;
    }

    if (!isIdUnique) {
      setAlertMessage('User ID must be unique');
      setShowAlert(true);
      return;
    }

    for (const url of apiUrls) {
      try {
        await axios.post(`${url}/admin/users`, editUser, {
          headers: { Authorization: token }
        });
        fetchUsers(token);
        setEditUser(null);
        return; // Exit once successful
      } catch (error) {
        setError('Error saving user');
      }
    }
  }
};


  const handleDeleteUser = async (email) => {
    for (const url of apiUrls) {
      try {
        await axios.delete(`${url}/admin/users/${email}`, {
          headers: { Authorization: token }
        });
        fetchUsers(token);
        return; // Exit once successful
      } catch (error) {
        setError('Error deleting user');
      }
    }
  };

  const handleDesignationChange = (designation) => {
    setEditUser(prevState => ({
      ...prevState,
      designations: prevState.designations.includes(designation)
        ? prevState.designations.filter(d => d !== designation)
        : [...prevState.designations, designation]
    }));
  };

  const handleTopicChange = (topic) => {
    setEditUser(prevState => ({
      ...prevState,
      topics: prevState.topics.includes(topic)
        ? prevState.topics.filter(t => t !== topic)
        : [...prevState.topics, topic]
    }));
  };

  const confirmDeleteUser = (user) => {
    setDeleteUser(user);
  };

  const handleConfirmDelete = async () => {
    if (deleteUser) {
      await handleDeleteUser(deleteUser.email);
      setDeleteUser(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setToken(null);
  };

  if (!token) {
    return (
      <Container>
        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" gutterBottom>
            Admin Login
          </Typography>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            disabled={loading}
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Admin Panel
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleAddUser}>
            Add User
          </Button>
          <List>
            {users.map((user) => (
              <React.Fragment key={user.email}>
                <ListItem>
                  <ListItemText
                    primary={user.email}
                    secondary={`User ID: ${user.id} | Countries: ${(user.designations || []).join(', ')} | Topics: ${(user.topics || []).join(', ')}`}
                  />
                  <IconButton edge="end" onClick={() => setEditUser(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={() => confirmDeleteUser(user)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
        {editUser && (
          <Dialog open={Boolean(editUser)} onClose={() => setEditUser(null)}>
            <DialogTitle>{editUser.email ? 'Edit User' : 'Add User'}</DialogTitle>
            <DialogContent>
              {showAlert && <Alert severity="error" onClose={() => setShowAlert(false)}>{alertMessage}</Alert>}
              <TextField
                label="Email"
                value={editUser.email}
                onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="User ID"
                value={editUser.id}
                onChange={(e) => setEditUser({ ...editUser, id: e.target.value })}
                fullWidth
                margin="normal"
              />
              <Typography variant="h6">Countries</Typography>
              <FormGroup>
                {possibleDesignations.map((designation) => (
                  <FormControlLabel
                    key={designation}
                    control={
                      <Checkbox
                        checked={(editUser.designations || []).includes(designation)}
                        onChange={() => handleDesignationChange(designation)}
                      />
                    }
                    label={designation}
                  />
                ))}
              </FormGroup>
              <Typography variant="h6">Topics</Typography>
              <FormGroup>
                {possibleTopics.map((topic) => (
                  <FormControlLabel
                    key={topic}
                    control={
                      <Checkbox
                        checked={(editUser.topics || []).includes(topic)}
                        onChange={() => handleTopicChange(topic)}
                      />
                    }
                    label={topic}
                  />
                ))}
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditUser(null)}>Cancel</Button>
              <Button onClick={handleSaveUser} color="primary">Save</Button>
            </DialogActions>
          </Dialog>
        )}
        {deleteUser && (
          <Dialog open={Boolean(deleteUser)} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete the user {deleteUser.email}?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete}>Cancel</Button>
              <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    </Box>
  );
};

export default AdminPage;
