import React, { useState, useEffect, useRef } from 'react'; // Added useRef for file input reference
import axios from 'axios';
import {
  AppBar, Toolbar, Typography, Button, FormControl, Box,
  List, ListItem, ListItemText, ListItemSecondaryAction, IconButton,
  ListItemAvatar, Avatar, LinearProgress, Dialog, DialogTitle,
  DialogContent, DialogActions, TextField, CircularProgress, Divider, MenuItem, Select,
  Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FolderIcon from '@mui/icons-material/Folder';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useMsal } from "@azure/msal-react";
import { useUser } from './UserProvider';
import FixedBottomButton from './FixedBottomButton';
import LoginDialog from './LoginDialog';
import { loginRequest } from './authConfig';
import './AppContent.css';
import moment from 'moment-timezone';

const AppContent = () => {
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedDesignation, setSelectedDesignation] = useState('');
  const [projectNumber, setProjectNumber] = useState('');
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loginDialogOpen, setLoginDialogOpen] = useState(true);
  const [allowedTopics, setAllowedTopics] = useState([]);
  const [allowedDesignations, setAllowedDesignations] = useState([]);
  const [tokenError, setTokenError] = useState(''); // State for token error
  const [apiMessage, setApiMessage] = useState(''); // New state for storing the API message

  const { instance, accounts } = useMsal();
  const { user, loginError, logout, setLoginError } = useUser();

  const fileInputRef = useRef(); // Reference to the file input element

  useEffect(() => {
    if (user) {
      fetchPermissions(user.email);
      setLoginDialogOpen(false);
    } else {
      setLoginDialogOpen(true);
    }
  }, [user]);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('msal.idtoken');
      if (!token) {
        setTokenError('');
        return;
      }

      try {
        const response = await axios.get('/validate-token', {
          headers: {
            'Authorization': `Bearer ${token}`  // Use backticks here for string interpolation
          }
        });
        if (response.status !== 200) {
          setTokenError('Unable to verify token, please sign out and back in');
        }
      } catch (err) {
        setTokenError('Unable to verify token, please sign out and back in');
      }
    };

    if (!loginDialogOpen) {
      validateToken();
    }
  }, [loginDialogOpen]);

  const fetchPermissions = async (email) => {
    const url = process.env.REACT_APP_API_URL_PROD;
    if (url) {
      try {
        console.log(`Trying to fetch permissions from ${url}/permissions`);
        const response = await axios.post(`${url}/permissions`, { email });
        setAllowedTopics(response.data.allowedTopics);
        setAllowedDesignations(response.data.allowedDesignations);
        console.log(`Successfully fetched permissions from ${url}/permissions`);
      } catch (error) {
        console.error(`Error fetching permissions from ${url}:`, error);
        console.error('Failed to fetch permissions.');
        setLoginError(`User ${email} is not allowed to use this application.`);
        setLoginDialogOpen(true);  // Reopen the login dialog on error
      }
    }
  };

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);

    // Reset the input value to allow re-selecting the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handlePhotoCapture = async (e) => {
    const photo = e.target.files[0];
    if (photo) {
      setFiles([...files, photo]);

      // Reset the input value to allow re-selecting the same file
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const renderFilePreview = (file) => {
    if (file.type.startsWith('image/')) {
      return <Avatar src={URL.createObjectURL(file)} variant="square" />;
    }
    return <Avatar>{file.name[0]}</Avatar>; // Default icon for non-image files
  };

  const handleProjectNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, ''); // Replace any non-numeric character with an empty string
    setProjectNumber(numericValue);
  };

  const handleUpload = async () => {
    if (!selectedTopic || !selectedDesignation || !projectNumber || files.length === 0) {
      setError('Please select a topic, country, provide a project number, and add photo files.');
      return;
    }

    setError('');
    setIsUploading(true);
    setUploadProgress(0); // Reset progress

    const url = process.env.REACT_APP_API_URL_PROD;
    const account = accounts[0];

    try {
      console.log('Starting batch upload...');

      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account
      });

      const token = response.idToken;

      if (!url) {
        console.error('Server URL is not set.');
        setError('Server URL is not configured.');
        return;
      }

      // Track the progress of each file
      const progressArray = Array(files.length).fill(0); // Array to track each file's upload progress
      let lastLoggedOverallProgress = 0; // To track the last logged progress in 5% steps

      // Function to upload a single file and track its progress
      const uploadFile = async (file, index) => {
        const formData = new FormData();
        formData.append('topic', selectedTopic.toLowerCase());
        formData.append('project', `${selectedDesignation}${projectNumber}`);
        formData.append('email', user.email);
        formData.append('files', file, file.name);

        console.log(`Starting upload for file: ${file.name}`); // Log when starting a file upload

        return axios.post(`${url}/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.round((loaded * 100) / total);
            
            // Update the progress for this specific file
            progressArray[index] = percentage;

            // Calculate the overall progress as the average of all files' progress
            const totalProgress = progressArray.reduce((acc, curr) => acc + curr, 0) / files.length;

            // Throttle logging to 5% steps
            if (Math.floor(totalProgress / 5) * 5 > lastLoggedOverallProgress) {
              lastLoggedOverallProgress = Math.floor(totalProgress / 5) * 5;
              console.log(`Overall progress: ${lastLoggedOverallProgress}%`);
            }

            // Update the overall progress in the UI
            setUploadProgress(totalProgress > 100 ? 100 : totalProgress);
          }
        }).then((response) => {
          console.log(`File ${file.name} uploaded successfully.`); // Log after a file upload is complete
          return response;
        }).catch((error) => {
          console.error(`Error uploading file ${file.name}:`, error);
          throw error; // Ensure the error is caught by Promise.all
        });
      };

      // Run the uploads concurrently
      const uploadPromises = files.map((file, index) => uploadFile(file, index));
      const responses = await Promise.all(uploadPromises);

      console.log('All files uploaded successfully.');

      // Handle batch completion logic
      await handleBatchCompletion(token);

      // Handle responses to check if all were successful
      if (responses.every(res => res.status === 200 || res.status === 201)) {
        setUploadSuccess(true);
        console.log('Batch upload complete.');
      } else {
        setError('Some files failed to upload.');
        console.error('Upload error:', responses);
      }
    } catch (error) {
      console.error('Error during upload or batch completion:', error);
      setError('Unable to upload files or complete the batch upload.');
    } finally {
      setIsUploading(false);
    }
  };

  // Batch completion logic after all uploads are finished
  const handleBatchCompletion = async (token) => {
    const payload = {
      topic: selectedTopic.toLowerCase(),
      project: `${selectedDesignation}${projectNumber}`,
      email: user.email
    };

    const url = process.env.REACT_APP_API_URL_PROD;

    try {
      const response = await axios.post(`${url}/completeUpload`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        console.log('Batch upload complete. API call made successfully.');
        setApiMessage(response.data.message);
      } else {
        setError('Failed to complete batch upload.');
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Batch completion error:', error);
      setError('Unable to complete batch upload.');
    }
  };

  const handleCloseSuccessDialog = () => {
    setUploadSuccess(false);
    setIsUploading(false); // Ensure the uploading state is reset
    setUploadProgress(0); // Ensure progress is reset
    setFiles([]);
    setSelectedTopic('');
    setSelectedDesignation('');
    setProjectNumber('');

    // Reset the input value to allow re-selecting the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Get the current date in Tallinn timezone and format it
  const getCurrentDateInTallinn = () => {
    return moment.tz('Europe/Tallinn').format('DD_MM_YYYY');
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">
            Granitop Photo Uploader
          </Typography>
          {user && (
            <IconButton edge="end" color="inherit" onClick={logout}>
              <LogoutIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Box
        className={loginDialogOpen ? 'blurred' : ''}
        sx={{ p: 2, pb: 14, maxWidth: 400, mx: 'auto', textAlign: 'center' }}
      >
        {user && !loginError && (
          <Alert icon={<CheckCircleIcon fontSize="inherit" />} severity="success" sx={{ mb: 2 }}>
            Logged in as <strong>{user.email}</strong>
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {tokenError && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            {tokenError}
          </Alert>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
          <FormControl component="fieldset" sx={{ mb: 0 }}>
            <Select
              labelId="select-topic-label"
              id="select-topic"
              value={selectedTopic}
              onChange={(e) => setSelectedTopic(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                Select a topic
              </MenuItem>
              {allowedTopics.map((topic, index) => (
                <MenuItem key={index} value={topic}>{topic}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl component="fieldset" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
              <ToggleButtonGroup
                value={selectedDesignation}
                exclusive
                onChange={(e, newDesignation) => setSelectedDesignation(newDesignation)}
                aria-label="designation"
              >
                {allowedDesignations.map((designation, index) => (
                  <ToggleButton key={index} value={designation}>
                    {designation}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
          </FormControl>
          <TextField
            sx={{ flex: 2 }}
            fullWidth
            label={`Project nr. (only numbers after ${selectedDesignation || 'eg. GE-'})`}
            placeholder="1234567890"
            value={projectNumber}
            onChange={handleProjectNumberChange}
            inputMode="numeric"
            type="tel"
          />
        </Box>

        <Button variant="contained" component="label" fullWidth sx={{ mb: 2 }} startIcon={<FolderIcon />}>
          Select photos from device
          <input
            type="file"
            multiple
            hidden
            onChange={handleFileChange}
            ref={fileInputRef} // Attach ref here
          />
        </Button>

        <Button variant="contained" component="label" fullWidth sx={{ mb: 2 }} startIcon={<CameraAltIcon />}>
          Take a new photo
          <input
            type="file"
            accept="image/*"
            capture="camera"
            hidden
            onChange={handlePhotoCapture}
            ref={fileInputRef} // Attach ref here
          />
        </Button>

        <List>
          {files.map((file, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemAvatar>
                  {renderFilePreview(file)}
                </ListItemAvatar>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleRemoveFile(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>

        <FixedBottomButton
          variant="contained"
          startIcon={<ArrowUpwardIcon />}
          onClick={handleUpload}
          className={selectedTopic && selectedDesignation && projectNumber && files.length > 0 ? 'shine' : ''}
        >
          Upload Photos
        </FixedBottomButton>

        <Dialog open={isUploading} maxWidth="xs" fullWidth>
          <DialogTitle>Uploading Photos</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
            <Typography variant="body2" align="center">Photos are being uploaded, please wait</Typography>
            <LinearProgress variant="determinate" value={uploadProgress} sx={{ my: 2 }} />
            <Typography variant="body2" align="center">Progress: {Math.round(uploadProgress)}%</Typography>
          </DialogContent>
        </Dialog>

        <Dialog open={uploadSuccess} maxWidth="xs" fullWidth>
          <DialogTitle>Upload Complete</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Typography>
                All files have been successfully uploaded to the folder{' '}
                <b>
                  <a href={`https://foto.granitop.ee/files/${selectedDesignation}${projectNumber}/${selectedTopic.toLowerCase()}_${getCurrentDateInTallinn()}/`} 
                     target="_blank" 
                     rel="noopener noreferrer">
                    {`${selectedDesignation}${projectNumber}/${selectedTopic.toLowerCase()}_${getCurrentDateInTallinn()}`}
                  </a>
                </b>.
                <br />
                Halduse vastus: {apiMessage} {/* Display the API message here */}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSuccessDialog}>Close</Button>
          </DialogActions>
        </Dialog>

        <LoginDialog open={loginDialogOpen} onClose={() => setLoginDialogOpen(false)} />
      </Box>
    </>
  );
};

export default AppContent;
