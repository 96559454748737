import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminLogin from './AdminLogin';
import AdminPage from './AdminPage';
import AppContent from './AppContent'; // Assuming this is your main app content component
import { MsalProvider } from "@azure/msal-react";
import { getMsalInstance } from './authConfig';
import { UserProvider } from './UserProvider';

const App = () => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(!!localStorage.getItem('adminToken'));
  const [msalInstance, setMsalInstance] = useState(null);
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);

  const handleAdminLogin = () => {
    setIsAdminAuthenticated(true);
  };

  useEffect(() => {
    const initializeMsalInstance = async () => {
      const instance = await getMsalInstance();
      setMsalInstance(instance);
      setIsMsalInitialized(true);
    };

    initializeMsalInstance();
  }, []);

  if (!isMsalInitialized) {
    return <div>Loading...</div>; // Or some loading indicator
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/admin/*"
          element={isAdminAuthenticated ? <AdminPage /> : <Navigate to="/admin/login" />}
        />
        <Route
          path="/admin/login"
          element={<AdminLogin onLogin={handleAdminLogin} />}
        />
        <Route
          path="/"
          element={
            <MsalProvider instance={msalInstance}>
              <UserProvider>
                <AppContent />
              </UserProvider>
            </MsalProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
