import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "6c0553ca-44ec-498c-87ab-e5d4f0b3b0f9", // Application (client) ID from Azure portal
    authority: "https://login.microsoftonline.com/6bba8230-30e5-414b-86df-625204a49f92", // Directory (tenant) ID from Azure portal
    redirectUri: window.location.origin, // Dynamically use the current origin
  },
  cache: {
    cacheLocation: "localStorage", // or sessionStorage
    storeAuthStateInCookie: false, // Set to true if you encounter issues with IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

let msalInstance;

export const initializeMsal = async () => {
  try {
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    await msalInstance.handleRedirectPromise();
  } catch (error) {
    console.error('MSAL initialization error:', error);
  }
};

export const getMsalInstance = async () => {
  if (!msalInstance) {
    await initializeMsal();
  }
  return msalInstance;
};

export default msalInstance;
