import React, { useState } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminLogin = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
    setError('');

    console.log('Attempting to log in...');
    console.log('Email:', email);
    console.log('Password:', password);

    const url = process.env.REACT_APP_API_URL_PROD || 'https://foto.granitop.ee';

    try {
      console.log(`Sending login request to ${url}/admin/login`);
      const response = await axios.post(`${url}/admin/login`, { email, password });
      console.log('Response status:', response.status);
      if (response.status === 200) {
        console.log('Login successful:', response.data);
        localStorage.setItem('adminToken', response.data.token);
        onLogin();
        navigate('/admin'); // Redirect to the admin page
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      setError('Error logging in');
      console.error('Login error:', error);
    } finally {
      setLoading(false);
      console.log('Login request completed');
    }
  };

  return (
    <Box sx={{ p: 2, maxWidth: 400, mx: 'auto', textAlign: 'center' }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Admin Login</Typography>
      <form onSubmit={handleLogin}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
       <TextField
  label="Password"
  type="password"
  fullWidth
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  sx={{ mb: 2 }}
  autocomplete="current-password" // Add this line
/>

        <Button variant="contained" type="submit" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
      </form>
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
    </Box>
  );
};

export default AdminLogin;
