import { styled } from '@mui/system';
import Button from '@mui/material/Button';

const FixedBottomButton = styled(Button)({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  left: 0,
  borderRadius: 0,
  height: '100px',
  fontSize: '18px',
  backgroundColor: 'green',
  color: 'white',
  '&:hover': {
    backgroundColor: 'darkgreen'
  }
});

export default FixedBottomButton;
