import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, Alert } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig';
import { useUser } from './UserProvider';

const LoginDialog = ({ open, onClose }) => {
  const { instance } = useMsal();
  const { isInteractionInProgress, setIsInteractionInProgress, loginError, setLoginError, logout, user, setUser } = useUser();

  const handleMicrosoftLogin = async () => {
    setLoginError('');  // Clear any previous errors
    if (isInteractionInProgress) {
      console.log('Another interaction is in progress');
      return;
    }

    setIsInteractionInProgress(true);
    try {
      await instance.loginPopup(loginRequest);
      onClose();  // Close the dialog after successful login
      window.location.reload();  // Refresh the page after successful login
    } catch (error) {
      console.error('Microsoft login error:', error);
      setLoginError('Authentication failed');  // Set error on failure
    } finally {
      setIsInteractionInProgress(false);
    }
  };

  useEffect(() => {
    if (user && !loginError) {
      onClose();
    }
  }, [user, loginError, onClose]);

  const handleLogout = () => {
    setUser(null);
    setLoginError('');
    instance.logoutRedirect({
      onRedirectNavigate: () => false, // Prevents navigation to Microsoft logout page
    });
    window.location.reload(); // Redirect to the app page itself
  };

  return (
    <Dialog open={open} onClose={() => {}} maxWidth="xs" fullWidth>
<DialogTitle sx={{ textAlign: 'center' }}>Authentication Required</DialogTitle>
      <DialogContent>
        {loginError && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Alert severity="error">
              User <strong>{user?.email}</strong> does not have the necessary permissions to use this application or the session tokens have expired. Please log in again in order to use the app.
            </Alert>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <Button variant="contained" onClick={handleMicrosoftLogin} disabled={isInteractionInProgress}>
            Login with Microsoft
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
